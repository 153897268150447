import * as React from "react"
import * as styles from "./ambassador.module.scss"

import pp from "../../images/icons/emojis/party_popper.png"
import rocket from "../../images/icons/emojis/rocket.png"
import headphone from "../../images/icons/emojis/headphone.png"
import fire from "../../images/icons/emojis/fire.png"
import hundred from "../../images/icons/emojis/hundred_points.png"
import trophy from "../../images/icons/emojis/trophy.png"
import seedling from "../../images/icons/emojis/seedling.png"
import gem from "../../images/icons/emojis/gem.png"
import stopwatch from "../../images/icons/emojis/stopwatch.png"
import key from "../../images/icons/emojis/key.png"
import dizzy from "../../images/icons/emojis/dizzy.png"
import star from "../../images/icons/emojis/glowing_star.png"
import present from "../../images/icons/emojis/wrapped_present.png"
import think from "../../images/icons/emojis/thinking_face.png"

const content = [
  {
    title:
      "Love Genie and want to be a part of the Genie family? Become a brand ambassador!",
    subcontent: [
      {
        emoji: pp,
        description:
          "Bring this new exciting app to your college that allows people to stay safe and still have fun!",
      },
      {
        emoji: rocket,
        description: "Be part of the Genie journey and help us grow!",
      },
      {
        emoji: headphone,
        description: "Great personal perks and chance to win amazing gifts",
      },
      {
        emoji: fire,
        description: "Cool exclusive merchandise",
      },
    ],
  },
  {
    title: "Boost your CV! I know a lot of startups give this chat but…",
    subcontent: [
      {
        emoji: hundred,
        description:
          "We can provide you with numbers that quantify your direct contribution to our growth",
      },
      {
        emoji: trophy,
        description: "You can lead your college to the top of the leaderboard",
      },
      {
        emoji: seedling,
        description: "You’ll learn how startups are built from the ground up",
      },
      {
        emoji: gem,
        description: "You can work with us to design and improve our service",
      },
    ],
  },
  {
    title: "How it works",
    subcontent: [
      {
        emoji: stopwatch,
        description: "Apply via our short online form",
      },
      {
        emoji: key,
        description:
          "Share your unique code with friends, coursemates, people in your college, your supervisor",
      },
      {
        emoji: dizzy,
        description: "Repost a few things on social media",
      },
      {
        emoji: star,
        description:
          "Every time someone orders using your code, you’ll earn points towards great prizes!",
      },
      {
        emoji: present,
        description: "Unlock perks and great prizes by hitting milestones",
      },
      {
        emoji: think,
        description: (
          <>
            For more details on your role and the rewards, send a message to{" "}
            <a href="mailto:tim@geniedelivery.co.uk">tim@geniedelivery.co.uk</a>
          </>
        ),
      },
    ],
  },
]

const Ambassador = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <div className={styles.contentContainer}>
      <h1 className={styles.title}>Become a Brand Ambassador</h1>
      {content.map((section) => (
        <div key={section.title} className={styles.section}>
          <h4 className={styles.sectionHeading}>{section.title}</h4>
          <div className={styles.subcontentWrapper}>
            {section.subcontent.map((item) => (
              <div key={item.description} className={styles.item}>
                <div className={styles.imageWrapper}>
                  <img className={styles.image} src={item.emoji} alt="" />
                </div>
                <p className={styles.description}>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default Ambassador
