import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import Ambassador from "../components/Ambassador"

const AmbassadorPage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Brand Ambassadors"
      description="We're recruiting brand ambassadors in Cambridge. Join the family and get access to exclusive rewards, perks and give your CV a boost."
    />
    <BasicPage>
      <Ambassador />
    </BasicPage>
  </Layout>
)

export default AmbassadorPage
